<template>
    <!--Main Navigation-->
    <header>
        <!-- Navbar -->
        <!-- Navbar -->
        <!-- Background image -->
        <div id="intro" class="bg-image shadow-2-strong">
            <div class="mask d-flex align-items-center h-100" style="background-color: rgba(0, 0, 0, 0.8);">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-md-8">
                            <form method="POST" class="bg-white rounded shadow-5-strong p-5" @submit.prevent="loginAction">
                                <!-- Email input -->
                                <div class="form-outline mb-4">
                                    <input type="email" id="form1Example1" v-model="authRequest.email" class="form-control" />
                                    <label class="form-label" for="form1Example1">Email address</label>
                                </div>

                                <!-- Password input -->
                                <div class="form-outline mb-4">
                                    <input type="password" id="form1Example2" v-model="authRequest.password" class="form-control" />
                                    <label class="form-label" for="form1Example2">Password</label>
                                </div>

                                <!-- 2 column grid layout for inline styling -->
                                <div class="row mb-4">
                                    <div class="col d-flex justify-content-center">
                                        <!-- Checkbox -->
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="form1Example3" checked />
                                            <label class="form-check-label" for="form1Example3">
                                                Remember me
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col text-center">
                                        <!-- Simple link -->
                                        <a href="#!">Forgot password?</a>
                                    </div>
                                </div>

                                <!-- Submit button -->
                                <button type="submit" class="btn btn-primary btn-block" click="">Sign in</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Background image -->
    </header>

</template>

<script charset="utf-8">
export default {
        components: {
            /*MDBBtn*/
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,

                authRequest: {
                    email: "",
                    password: "",
                }
            };
        },
        computed: {

        },
        methods: {
            async loginAction() {

                try {
                    console.log(this.authRequest);
                    this.$store.dispatch("auth/login", this.authRequest)
                        .then(

                            (res) => {
                                console.log(res);

                            },
                            (err) => {

                                console.log(err)
                            })

                }
                catch (e) {

                    console.log(e);
                    if (e.response.status == 401) {
                        console.log(e.response);

                    }

                }

            }
        }
}
</script>
<style type="text/css">
    #intro {
        background-image: url(https://mdbootstrap.com/img/new/fluid/city/008.jpg);
        height: 100vh;
    }

    .navbar .nav-link {
        color: #fff !important;
    }
</style>