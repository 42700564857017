<template>
    <div class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center vissonic-loader">
        <div class="spinner-border" role="status">
            <span class="sr-only">Ładowanie...</span>
        </div>
    </div>
</template>

<script charset="utf-8">
    export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL
            };
        },
        computed: {

        },
        methods: {
        }
    }
</script>
<style type="text/css">
    .vissonic-loader{
        background: rgb(255, 255, 255, 0.6);
    }
</style>

