<template>
    <nav id="sidebarMenu"
         class="collapse d-lg-block sidebar collapse bg-white">
        <div class="position-sticky">
            <div class="list-group list-group-flush mt-4">
                <a href="#"
                   class="list-group-item list-group-item-action py-2 ripple"
                   aria-current="true">
                    <i class="fas fa-tachometer-alt fa-fw me-3"></i><span>Pulpit</span>
                </a>
                <router-link :to="{name: 'AppAnalyzer'}"
                             class="list-group-item list-group-item-action py-2 ripple">
                    <i class="fas fa-chart-area fa-fw me-3"></i><span>Analiza apllikacje</span>
                </router-link>
                <router-link :to="{name: 'IntegrationList'}"
                             class="list-group-item list-group-item-action py-2 ripple">
                    <i class="fas fa-file-zipper fa-fw me-3"></i><span>Integracje</span>
                </router-link>
                <!--<a href="#"
                   class="list-group-item list-group-item-action py-2 ripple"><i class="fas fa-users fa-fw me-3"></i><span>Users</span></a>
                <a href="#"
                   class="list-group-item list-group-item-action py-2 ripple"><i class="fas fa-money-bill fa-fw me-3"></i><span>Sales</span></a>-->
            </div>
        </div>
    </nav>
</template>

<script charset="utf-8">
export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,


            };
        },
        computed: {
            isExactActive() {
                return this.$route.fullPath == this.resolvedRoute.route.fullPath
            },
            resolvedRoute() {
                return this.$router.resolve(this.to)
            }
        },
        methods: {


        }
}
</script>