import { encryptStorage } from "@/services/common/encrypt.service";

class TokenService {

    GetAccessToken() {
        let item = encryptStorage.getItem("vissonicApp");
        return item?.token;
    }

    SetItem(item) {
        encryptStorage.setItem("vissonicApp", JSON.stringify(item))
    }
    RemoveItem() {
        encryptStorage.removeItem("vissonicApp");
    }
}

export default new TokenService();