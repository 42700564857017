<template>
    {{eventId}}
    <div class="row">
        <div class="col-sm-12 col-md-12 col-xl-6 col-xxl-6 ">
            <SettingAnalyzer :eventId="eventId" />
        </div>

    </div>
</template>

<script charset="utf-8">
    import SettingAnalyzer from "@/components/analyzer/SettingAnalyzer.vue";

    export default {
        components: {
            SettingAnalyzer
        },
        data() {
            return {
            };
        },
        computed: {
            eventId() {
                return this.$route.params.eventRunId
            }
        },
        methods: {
        }
    }</script>


