<template>
    <div class="row">
        <div class="col-6">
            <SettingAnalyzer :token="testToken" />
        </div>
        <div class="col-6">
            <HandshakeAnalyzer :token="testToken" />
        </div>
    </div>
    <div class="row">
        <button class="btn btn-primary" @click="clear()">Czyść</button>
        <button class="btn btn-primary" @click="set()">Ustaw</button>
    </div>
</template>

<script charset="utf-8">

    import SettingAnalyzer from "@/components/analyzer/SettingAnalyzer.vue";
    import HandshakeAnalyzer from "@/components/analyzer/HandshakeAnalyzer.vue";

    export default {
        components: {
            SettingAnalyzer,
            HandshakeAnalyzer
        },
        data() {
            return {
                testToken: "2a5dd4c4",
                publicPath: process.env.BASE_URL
            };
        },
        computed: {

        },
        methods: {
            clear(){

                this.testToken = "abd";
            },
            set(){

                this.testToken = "2a5dd4c4";
            },
        }
    }</script>


