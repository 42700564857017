<template>
    <div class="card">
        <div class="card-header text-center py-3">
            <h5 class="mb-0 text-center">
                <strong>Handshake</strong>
            </h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-3">
                    <button class="btn btn-primary" @click="showOnlyErrors()">Pokaż tylko błędy</button>
                </div>
                <div class="col-3">
                    <button class="btn btn-primary" @click="showAll()">Pokaż wszystko</button>
                </div>
            </div>
            <div class="row">
                <DataTable ref="handshakeDataTable"
                           :columns="columns"
                           :data="hanshakeLogs"
                           :options="{ select: true }"
                           class="table table-hover table-striped"
                           width="100%">
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>DIFF</th>
                            <th>Direction</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>Timestamp</th>
                            <th>DIFF</th>

                            <th>Direction</th>
                            <th>Message</th>
                        </tr>
                    </tfoot>

                </DataTable>
            </div>

        </div>
        <BoxLoader v-if="loading"/>
    </div>
</template>

<script charset="utf-8">
    import DataTable from 'datatables.net-vue3';
    import DataTablesLib from 'datatables.net-bs5';
    import 'datatables.net-select-bs5';
    DataTable.use(DataTablesLib);

    import BoxLoader from "@/components/common/BoxLoader.vue"

    import ApplogsDeviceService from "@/services/applogs/applogs.device.service.js";
    export default {
        components: {
            DataTable,
            BoxLoader
        },
        props: {
            token: {
                type: String,
                require: true,
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                dt: null,
                originalData: [],
                hanshakeLogs: [],
                columns: [
                    { data: "timestamp" },
                    { data: "diff" },
                    { data: "direction" },
                    { data: "message" },
                ],
                loading: false,
            };
        },

        watch: {
            token: {
                immediate: true,
                handler(val) {
                    this.getCurrentClientAppSetting(val)
                }
            }
        },
        computed: {

        },
        mounted() {
        },
        methods: {

            async getCurrentClientAppSetting(token) {
                try {
                    this.loading = true;
                    let res = (await ApplogsDeviceService.GetHandshakeByToken(token))
                    this.originalData = res.data;
                    this.showAll()

                    //this.$refs.handshakeDataTable.createdRow

                    console.log(this.$refs);
                    console.log(this.$refs.handshakeDataTable.api());
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading = false;
                }
            },
            toBytes(str) {
                return Array.from(Buffer.from(str, 'utf8'));
            },
            showOnlyErrors() {
                try {
                    this.loading = true;
                    this.hanshakeLogs = this.hanshakeLogs.filter(function (item) {
                        return item.isError == true;
                    });
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading = false;
                }
            },

            showAll() {
                try {
                    this.loading = true;
                    this.hanshakeLogs = this.originalData.map(function (item) {
                        let direction = item.direction.toString();
                        item.direction = direction.replace("1", "Device -> App").replace("0", "App -> Device")
                        return item;
                    });
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading = false;
                }

            }

        }
    }
</script>
<style type="text/css">
    @import 'datatables.net-bs5';
    @import 'datatables.net-select-bs5';
</style>


