import url from "@/services/common/url";
import api from "@/services/common/api";

class AppLogsSettingsService {
    GetAll() {
        return api.get(url.appLogs.settings.getAll)
            .then((res) => { return res });
    }

    GetById(id) {
        return api.get(url.appLogs.settings.getById + "/" + id)
            .then((res) => { return res });
    }

    GetByEventId(id) {
        return api.get(url.appLogs.settings.getByEventId + "/" + id)
            .then((res) => { return res });
    }

    GetNewestByEventId(id) {

        return api.get(url.appLogs.settings.getNewestBeEventId.replace("EVENT_ID", id))
            .then((res) => { return res });
    }

    GetByToken(token) {
        return api.get(url.appLogs.settings.getByToken + "/" + token)
            .then((res) => { return res });
    }

    GetNewestByToken(token) {
        return api.get(url.appLogs.settings.getNewestByToken + "/" + token)
            .then((res) => { return res });
    }
}

export default new AppLogsSettingsService();