import { createRouter, createWebHashHistory } from 'vue-router'

import LayoutView from "@/views/layout/LayoutView.vue";
import DashboardView from "@/views/dashboard/DashboardView.vue";
import AnalyzerView from "@/views/analyzer/AnalyzerView.vue";
import AppAnalyzerView from "@/views/analyzer/app-analyzer/AppAnalyzerView.vue" 
import AppAnalyzerDetail from "@/views/analyzer/app-analyzer/AppAnalyzerDetail.vue"


import IntegrationList from "@/views/integration/IntegrationList.vue";
import IntegrationDetail from "@/views/integration/IntegrationDetail.vue"
import IntegrationAdd from "@/views/integration/IntegrationAdd.vue"

//auth
import AuthLogin from "@/views/auth/AuthLogin.vue";



import store from "@/store/index";
const routes = [
    {
        path: "/",
        name: "Layout",
        component: LayoutView,
        meta: {
            requiresAuth: true,
            title: "Vissonic App"
        },
        children: [
            {
                path: 'dashboard',
                name: "Dashboard",
                component: DashboardView,
                meta: {
                    requiresAuth: true,
                    title: "Vissonic App - Pulpit"
                },
            },
            {
                path: 'logs',
                name: "Logs",
                meta: {
                    requiresAuth: true,
                    title: "Vissonic App - Logs"
                },
                children: [
                    {
                        path: 'analyzer',
                        name: "LogsAnalyzer",
                        component: AnalyzerView,
                        meta: {
                            requiresAuth: true,
                            title: "Vissonic App - Analizator logów"
                        },
                    },
                    {
                        path: 'app',
                        name: "AppAnalyzer",
                        component: AppAnalyzerView,
                        meta: {
                            requiresAuth: true,
                            title: "Vissonic App - Analizator aplikacji"
                        },
                    },
                    {
                        path: 'app/analyze/:eventRunId',
                        name: "AppAnalyzerDetail",
                        component: AppAnalyzerDetail,
                        meta: {
                            requiresAuth: true,
                            title: "Vissonic App - Analizator aplikacji"
                        },
                    }
                ]
            },
            {
                path: 'integration',
                name: "Integration",
                meta: {
                    requiresAuth: true,
                    title: "Vissonic App - Integracja"
                },
                children: [
                    {
                        path: 'list',
                        name: "IntegrationList",
                        component: IntegrationList,
                        meta: {
                            requiresAuth: true,
                            title: "Vissonic App - Integracja - Lista"
                        },
                    },
                    {
                        path: 'details/:id',
                        name: "IntegrationDetails",
                        component: IntegrationDetail,
                        meta: {
                            requiresAuth: true,
                            title: "Vissonic App - Integracja - Szczegóły"
                        },
                    },
                    {
                        path: 'add',
                        name: "IntegrationAdd",
                        component: IntegrationAdd,
                        meta: {
                            requiresAuth: true,
                            title: "Vissonic App - Integracja - Dodaj"
                        },
                    },
                ]
            }
        ]
    },
    {
        path: '/auth',
        name: 'Auth',
        meta: {
            requiresAuth: false,
            title: "Autoryzacja"
        },
        children: [
            {
                path: 'login',
                name: 'AuthLogin',
                component: AuthLogin,
                meta: {
                    requiresAuth: false,
                    title: "Autoryzacja - Logowanie"
                },
            }
        ]
    }
]

const router = createRouter({
    //mode: 'history',
    history: createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    document.title = "Vissonic App"


    let redirect;

    if (to.meta.title)
        document.title = to.meta.title

    if (to.meta.requiresAuth) {
        if (store.state.auth.status.loggedIn) {
            if (to.path == '/') {
                redirect = '/Dashboard'
            }
        }
        else {
            redirect = '/auth/login'
        }

    }
    else {
        if (to.name == "AuthLogin") {
            if (store.state.auth.status.loggedIn) {
                redirect = '/Dashboard'
            }

        }
    }

    next(redirect)

});
export default router