<template>
    <div class="row">
        <div class="col-12">
            <div class="alert alert-danger" role="alert" data-mdb-color="danger">
                <i class="fas fa-circle-exclamation"></i> {{message}}
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">export default {
        components: {
        },
        props: {
            message: {
                type: String,
                require: true,
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL
            };
        },
        computed: {

        },
        methods: {
        }
    }</script>


