import { createStore } from "vuex";

import { auth } from "@/store/modules/auth.store.module"

const store = createStore({
    modules: {
        auth,
    }
});

export default store;