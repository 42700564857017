import url from "@/services/common/url";
import api from "@/services/common/api";
import TokenService from "@/services/common/token.service";

class AuthService {
    Login() {
        return api.post(url.auth.login)
            .then((res) => {
                if (res.data.token)
                    TokenService.SetItem(res.data);
                return res;
            });
    }
}

export default new AuthService();