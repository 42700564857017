import AuthService from "@/services/auth/auth.service"
import TokenService from "@/services/common/token.service";

const token = TokenService.GetAccessToken();
const initialState = token ?
    { status: { loggedIn: true } } : { status: { loggedIn: false } };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, req) {
            console.log(req);
            return AuthService.Login().then(
                res => {
                    commit("LogiSuccess");
                    return Promise.resolve(res)
                },
                err => {
                    commit("LoginFailure");
                    return Promise.reject(err.response);
                }
            )
        }
    },
    mutations: {
        LogiSuccess(state) {
            state.status.loggedIn = true;
        },
        LoginFailure(state) {
            state.status.loggedIn = false;
        }
    }
}