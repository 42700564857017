<template>
    <form>
        <div class="row">
            <div class="col-5">
                <div class="card">
                    <div class="card-header">
                        <h2 class="page-title">Szczegóły</h2>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="form-outline">
                                    <input type="text" id="formVersion" class="form-control form-control-lg custom-form-control" />
                                    <label class="form-label custom-form-label" for="formVersion">Wersja</label>
                                </div>

                            </div>
                            <div class="col-12">
                                <div class="form-outline">
                                    <input type="text" id="formVersion" class="form-control form-control-lg custom-form-control" />
                                    <label class="form-label custom-form-label" for="formVersion">Wersja</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <p class="fs-5 fw-bold mb-0">Data dodania</p>
                                <input class="form-control" />
                                <!--<p class="fs-5 fw-bold">{{integrationData.created}}</p>-->
                            </div>
                            <div class="col-12">
                                <p class="fs-5 fw-bold mb-0">Ostatnia aktualizacja</p>
                                <input class="form-control" />
                                <!--<p class="fs-5 fw-bold">{{integrationData.updated}}</p>-->
                            </div>
                        </div>
                    </div>
                </div>
                <BoxLoader v-if="loading.fetch" />
            </div>
        </div>
    </form>
</template>

<script charset="utf-8">
    //import IntegrationService from "@/services/integration/integration.service";
    import BoxLoader from "@/components/common/BoxLoader.vue"
    export default {
        components: {
            BoxLoader
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                integrationList: [],

                loading: {
                    fetch: false
                },
            };
        },
        computed: {

        },
        mounted() {

        },
        methods: {
        }
    }
</script>


