import axiosInstanse from "@/services/common/api";
import TokenService from "@/services/common/token.service"

const setup = () => {
    axiosInstanse.interceptors.request.use(
        (config) => {
            const token = TokenService.GetAccessToken();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }
            return config;
        }
    );
};

export default setup;