<template>
    <header>
        <SidebarComponent />
        <HeaderComponent />
    </header>
    <main style="margin-top: 58px">
        <div class="container-fluid pt-4">
            <router-view />
        </div>
    </main>
</template>

<script charset="utf-8">
    import HeaderComponent from "@/components/layout/HeaderComponent.vue";
    import SidebarComponent from "@/components/layout/SidebarComponent.vue";
    export default {
        components: {
            HeaderComponent,
            SidebarComponent
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,


            };
        },
        computed: {

        },
        methods: {


        }
    }
</script>
<style type="text/css">
    body {
        background-color: #fbfbfb;
    }

    @media (min-width: 991.98px) {
        main {
            padding-left: 240px;
        }
    }

    /* Sidebar */
    .sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        padding: 58px 0 0; /* Height of navbar */
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
        width: 240px;
        z-index: 600;
    }

    @media (max-width: 991.98px) {
        .sidebar {
            width: 100%;
        }
    }

    .sidebar .active {
        border-radius: 5px;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    }

    .sidebar-sticky {
        position: relative;
        top: 0;
        height: calc(100vh - 48px);
        padding-top: 0.5rem;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    }
    .card-table{
        padding:0 !important;
    }
</style>