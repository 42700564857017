import url from "@/services/common/url";
import api from "@/services/common/api";

class ApplogsDeviceService{
    GetHandshakeByToken(token) {
        return api.get(url.appLogs.device.getHandshakeByToken + "/" + token)
            .then((res) => { return res });
    }
}

export default new ApplogsDeviceService();